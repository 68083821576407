import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import LoadingPage from '../LoadingPage';
import startPageQuery from './StartPageQuery.gql';

import { useStyleApplier } from '../ui/styles/useStyleApplier';
import { allContentComponents } from '../ContentEditor/ContentComponents';

const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StartPage = ({ startPageId }) => {
  console.log(startPageId);
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading, data, error } = result;
  // console.log(data,loading,error)

  const items = result?.data?.startPage?.data?.items;

  // Applies correct styles to the components
  useStyleApplier(items ?? []);

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageWrapper>
      <StartPageRenderer
        result={result}
        startPageComponents={allContentComponents}
        LoadingPage={LoadingPage}
      />
    </StartPageWrapper>
  );
};

export default StartPage;
